import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Login } from '../models/Login';
 
import { ListResponse } from '../models/ListResponse';
import { retry, catchError, tap, shareReplay, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServicesResponse } from '../models/ServicesResponse';
import { LoadingService } from './loading.service';

const httpOption = {
  header: new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
  })
}


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public first: string = "";
  public prev: string = "";
  public next: string = "";
  public last: string = "";

  private baseUrl = environment.baseUrl;
  private cashMenu$: Observable<ListResponse[]>;
  private cashServices$: Observable<ServicesResponse[]>;

  private lang: string = 'ar';

  constructor(private http: HttpClient, private loading: LoadingService) {
    //this.logIn();

    this.lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    // if (this.isLoggedOut()) {
    //   this.logIn();
    // }
  }

  /** utilities */
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    //console.log(errorMessage);
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors

      if (error.status === 401) {
        console.log('Error');
        this.logIn();
      }
      errorMessage = `Error Code: ${error.status}\\nMessage: ${error.message}`;
    }

    //return throwError(errorMessage);
    return errorMessage;
  }


  logInFirst() {
    // const url = `${this.baseUrl}/Login/Authenticate`;
    // const params = new HttpParams()
    //   .set('username', environment.apiUsername)
    //   .set('password', environment.apiPassword);

    // return this.http.post<Login>(url, params.toString(),
    //   {
    //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //   });
  }

  logIn() {
    // const url = `${this.baseUrl}/Login/Authenticate`;
    // const params = new HttpParams()
    //   .set('username', environment.apiUsername)
    //   .set('password', environment.apiPassword);

    // let tokenString = this.http.post<Login>(url, params.toString(),
    //   {
    //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //   })
    //   .subscribe((res: any) => {
    //     this.setWithExpiry(res);
    //   });

    // return true;
  }

  async logInAsync() {
  //   if (this.isLoggedOut()) {
  //     const url = `${this.baseUrl}/Login/Authenticate`;
  //     const params = new HttpParams()
  //       .set('username', environment.apiUsername)
  //       .set('password', environment.apiPassword);

  //     let tokenString = await firstValueFrom(this.http.post<Login>(url, params.toString(),
  //       {
  //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  //       }));

  //     this.setWithExpiry(tokenString);
  //   }
  }

  // private setSession(authResult: any) {
  //   const expireAt = moment().add(120, 'second');

  //   localStorage.setItem('id_token', authResult.Token);
  //   localStorage.setItem('expire_at', JSON.stringify(expireAt.valueOf()));
  // }

  private setWithExpiry(authResult: any) {
    const now = new Date();

    const item = {
      value: authResult.Token,
      expiry: now.getTime() + 10000
    }


    localStorage.setItem('id_token', authResult.Token);
    localStorage.setItem('expire_at', JSON.stringify(item));
  }

  private getWithExpiry() {
    const itemStr = localStorage.getItem('expire_at');
    if (!itemStr) {
      return false;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem('expire_at');
      localStorage.removeItem('expire_at');

      return false;
    }

    return true;
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expire_at');
  }

  isLoggedIn() {
    return this.getWithExpiry();
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  // getExpiration() {
  //   const expiration = localStorage.getItem('expire_at');
  //   const expireAt = JSON.stringify(expiration);
  //   //console.log(moment(expireAt));
  //   return moment(expireAt);
  // }


  /* Main api functions */
  get menuGet() {
    if (!this.cashMenu$) {
      this.cashMenu$ = this.getList('sitemenu').pipe(
        shareReplay(10)
      );
    }
    return this.cashMenu$;
  }

  /* Main api functions */
  get servicesGet() {
    if (!this.cashServices$) {
      this.cashServices$ = this.getList('Services').pipe(
        shareReplay(10)
      );
    }
    return this.cashServices$;
  }

  /** get any list data by name */
  getList(listName: string): Observable<any> {
    const url = `${this.baseUrl}/GetListsItems?listName=${listName}&lang=${this.lang}&siteName=${this.lang}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }

  /** get any list item by list name and itemId */
  getListById(listName: string, id: number): Observable<any> {
    const url = `${this.baseUrl}/GetItemById?listName=${listName}&id=${id}&lang=${this.lang}&siteName=${this.lang}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }

  /** get topN data from list by name and N*/
  getListTopN(listName: string, n: number, isAsc: boolean, filterName: string, filterValue: any,ID?:number) {

    //const url = `${this.baseUrl}/GetListsItemsFilterd?listName=${listName}&count=${n}&IsAsc=${isAsc}&filterName=${filterName}&filterValue=${filterValue}&lang=${this.lang}`;
    const url = `${this.baseUrl}/GetListsItemsFilterdPaging?listName=${listName}&count=${n}&IsAsc=${isAsc}&filterName=${filterName}&filterValue=${filterValue}&lang=${this.lang}&Id=${ID}&siteName=${this.lang}`;
//                               
    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }
  /** get Max item ID from list data by name */ 
  GetListMaxID(listName: string): Observable<any> {
    const url = `${this.baseUrl}/GetListMaxID?listName=${listName}&lang=${this.lang}&siteName=${this.lang}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }
  /** get topN data from list by name and Order the result by filterName*/
  getListOrder(listName: string, n: number, isAsc: boolean, filterName: string) {

    const url = `${this.baseUrl}/GetListsItemsOrder?listName=${listName}&count=${n}&IsAsc=${isAsc}&orderField=${filterName}&lang=${this.lang}&siteName=${this.lang}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }

   /** get active poll answers with percentage */ 
   GetActivePollAnswer(): Observable<any> {
    const url = `${this.baseUrl}/GetPollAnswers?listName=PollAnswers&lang=${this.lang}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }

  /** get topN data from list by name and two conditions with order */
  getListWithTwoConditionsTopN(listName: string, n: number, isAsc: boolean, filterName1: string, filterValue1: any, filterName2: string, filterValue2: any,
    filterOperation1: string, condition: string, filterOperation2: string) {

    const url = `${this.baseUrl}/GetListsItemsFilterdNew?listName=${listName}&count=${n}&IsAsc=${isAsc}&filterName=${filterName1}&filterValue=${filterValue1}&filterOperation=${filterOperation1}&condition=${condition}&filterName2=${filterName2}&filterValue2=${filterValue2}&filterOperation2=${filterOperation2}&lang=${this.lang}&siteName=${this.lang}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }
  /** get topN data from list by name and Order the result by filterName*/
  GetListsItemsFilterd(listName: string, n: number, isAsc: boolean, filterName: string,filterValue: string) {

    const url = `${this.baseUrl}/GetListsItemsFilterd?listName=${listName}&count=${n}&IsAsc=${isAsc}&filterName=${filterName}&filterValue=${filterValue}&lang=${this.lang}&siteName=${this.lang}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }
  /** get topN data from list by name and two conditions with order by specific column*/
  getListsItemsFilterdOrderd(listName: string, n: number, isAsc: boolean, orderField: string, filterName1: string, filterValue1: any, filterName2: string, filterValue2: any,
    filterOperation1: string, condition: string, filterOperation2: string) {

    const url = `${this.baseUrl}/GetListsItemsFilterdOrderd?listName=${listName}&count=${n}&IsAsc=${isAsc}&orderField=${orderField}&filterName=${filterName1}&filterValue=${filterValue1}&filterOperation=${filterOperation1}&condition=&filterName2=${filterName2}&filterValue2=${filterValue2}&filterOperation2=${filterOperation2}&lang=${this.lang}&siteName=${this.lang}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }

   /** get service list tabs by serviceID */
   checkService(serviceId: number): Observable<any> {
    const url = `${this.baseUrl}/CheckService?serviceId=${serviceId}&lang=${this.lang}&siteName=${this.lang}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }


  /** Update visiting count */
  putVisitingCount(listName: string, itemId: string) {
    const url = `${this.baseUrl}/UpdateVistingCount?listName=${listName}&id=${itemId}&lang=${this.lang}&siteName=${this.lang}`;
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));;
  }

  /** update item status */
  putStatusItem(listName: string, itemId: number, status: boolean) {
    const url = `${this.baseUrl}/ChangeItemStatus?listName=${listName}&id=${itemId}&status=${status}&lang=${this.lang}&siteName=${this.lang}`;
    this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }

  /** save poll action */
  putPollData(itemId: number, pollChiceId: string) {
    const url = `${this.baseUrl}/PostPoll?listName=PollAnswers&pollId=${itemId}&pollChoiceId=${pollChiceId}&lang=${this.lang}&siteName=${this.lang}`;
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }

  /** save poll action */
  putDowoadFile(fullName: string, mobile: string, emailAddress: string, purpose: string) {
    const url = `${this.baseUrl}/PostDownloadedFilesPersonalInformations?listName=DownloadedFilesPersonalInformations&FullName=${fullName}&MobileNumber=${mobile}&EmailAddress=${emailAddress}&Purpose=${purpose}&lang=${this.lang}&siteName=${this.lang}`;
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }

  /** save poll action */
  subscribe(email: string) {
    if (this.isLoggedOut()) {
      this.logIn();
    }

    const url = `${this.baseUrl}/PostNewsLetterSubscriber?listName=NewsLetterSubscribers&email=${email}&lang=${this.lang}&siteName=${this.lang}`;
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }
  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

}